<template>
  <StatusBadge :variant="statusBadgeVariant[status]">
    <LucideIcon :icon="statusIcon[status]" />

    <TextEllipsis>
      {{ translateCommercialConditionStatus.fr[status] }}
    </TextEllipsis>

    <!-- The slot enabled to inject a <StatusBadgeRemove /> component -->
    <slot></slot>
  </StatusBadge>
</template>

<script setup lang="ts">
import type { StatusBadgeVariant } from "~/components/ui/status-badge/types";
import type { LucideIconName } from "~/components/lucide-icon/types";
import type { CommercialConditionStatus } from "~/utils/supabase.enum";
import { translateCommercialConditionStatus } from "~/utils/translate";

defineProps<{ status: CommercialConditionStatus }>();

const statusBadgeVariant: Record<CommercialConditionStatus, StatusBadgeVariant> = {
  not_signed: "warning",
  to_validate: "warning",
  signed: "success",
  refused: "neutral",
  canceled: "neutral",
};

const statusIcon: Record<CommercialConditionStatus, LucideIconName> = {
  not_signed: "CircleDashed",
  to_validate: "CircleDashed",
  signed: "CheckCircle",
  refused: "CircleSlash",
  canceled: "CircleSlash",
};
</script>

<style lang="scss" scoped></style>
