<template>
  <Row :is-header="isHeader" :style="`grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr`">
    <CellHeader v-if="isHeader"> Société </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <div v-if="!isReady" class="PreviewFile skeleton-loading"></div>
      <FilePreviewTooltip v-if="!disabledDocumentPreview && fileToDisplay?.filestack_url" :file="fileToDisplay">
        <img :src="thumbnail(fileToDisplay?.filestack_url)" class="PreviewFile" />
      </FilePreviewTooltip>
      <Tag>
        <TagIcon :icon="mapIcon.company" />
        <TagText> {{ commercialCondition.company?.name }} </TagText>
      </Tag>
    </Cell>

    <CellHeader v-if="isHeader"> Type </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <TextEllipsis>
        {{ translateCommercialConditionType.fr[commercialCondition.type] }}
      </TextEllipsis>
    </Cell>

    <CellHeader v-if="isHeader"> Business Unit </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <TextEllipsis>
        {{ commercialCondition.business_unit?.emoji }} {{ commercialCondition.business_unit?.name }}
      </TextEllipsis>
    </Cell>

    <CellHeader v-if="isHeader">Statut</CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <PopoverRoot v-else-if="commercialCondition" v-model:open="openStatusPopover">
      <PopoverTrigger as-child @click.stop>
        <Cell>
          <PropertyContent action="select">
            <CommercialConditionStatusBadge :status="commercialCondition.status" />
          </PropertyContent>
        </Cell>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent :align="`start`" :side-offset="-57" :align-offset="-1">
          <CommercialConditionStatusSuperCombobox
            style="width: 256px"
            :model-value="commercialCondition.status ? [commercialCondition.status] : []"
            :is-asap-commercial-condition="isAsapCommercialCondition"
            :manager-id="commercialConditionOwner?.manager_id ?? undefined"
            @on-select="
              (status) => {
                if (status === 'signed') showUploadSignedDocumentDialog = true;
                else {
                  updateStatus(status);
                  openStatusPopover = false;
                }
              }
            "
            @on-close="showCommercialConditionStatusSelectModal = false"
          />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>

    <CellHeader v-if="isHeader">Date</CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <Tooltip
        v-if="commercialCondition.created_by"
        :content="`Créé par ${getFullName(commercialCondition.created_by)}`"
      >
        <AuthUserAvatar v-if="commercialCondition.created_by" :auth-user="commercialCondition.created_by" />
      </Tooltip>

      {{ dateFormat(commercialCondition.created_at, { options: { dateStyle: "medium" } }) }}
    </Cell>

    <!-- ---------------- -->
    <!-- Modals & Dialogs -->
    <!-- ---------------- -->

    <Dialog v-if="showUploadSignedDocumentDialog" @on-close="showUploadSignedDocumentDialog = false">
      <DialogContent>
        <DialogCloseButton @click="showUploadSignedDocumentDialog = false" />
        <DialogBody>
          <DialogInfo>
            <DialogTitle>✍️ Déposer le document signé</DialogTitle>
            <DialogDescription>Merci de déposer la condition commerciale signée par le client.</DialogDescription>
          </DialogInfo>

          <FormField :icon="mapIcon.commercial_condition" label="Conditions commerciales signées">
            <img
              v-if="commercialConditionSignedFile?.filestack_url"
              :src="thumbnail(commercialConditionSignedFile.filestack_url)"
              class="resume"
            />

            <FilestackToSupabaseDropzone
              v-else
              :accept="['application/pdf']"
              @on-upload-done="
                (files) => {
                  commercialConditionSignedFile = files[0];
                }
              "
            >
              <DropzoneText>
                <TextEllipsis> Déposer la condition commerciale signée par le client </TextEllipsis>
              </DropzoneText>
            </FilestackToSupabaseDropzone>
          </FormField>
        </DialogBody>

        <DialogFooter>
          <Button variant="outline" @click="showUploadSignedDocumentDialog = false">Annuler</Button>

          <Button
            :disabled="!commercialConditionSignedFile"
            @click="if (commercialConditionSignedFile) uploadSignedFile(commercialConditionSignedFile.id);"
          >
            <ButtonIcon :icon="mapIcon.check" />
            Enregistrer
            <ButtonLoadingSpinner v-if="isUploading" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </Row>
</template>

<script setup lang="ts">
import { PopoverContent, PopoverPortal, PopoverRoot, PopoverTrigger } from "radix-vue";
import type { CommercialCondition, SupabaseFile } from "~/utils/supabase.types";
import type { CommercialConditionStatus } from "~/utils/supabase.enum";
import { commercialConditionColumns } from "~/utils/supabase.columns";
import { useFilestackUtils } from "~/composables/useFilestack";
import FilestackToSupabaseDropzone from "../filestack/FilestackToSupabaseDropzone.vue";

const props = defineProps<{ isHeader?: boolean; commercialCondition?: CommercialCondition; isSkeleton?: boolean }>();

const emit = defineEmits<{ "update:commercialCondition": [value: CommercialCondition] }>();

const { isReady, thumbnail } = useFilestackUtils();

const showCommercialConditionStatusSelectModal = ref(false);

const showUploadSignedDocumentDialog = ref(false);

const openStatusPopover = ref(false);
const isAsapCommercialCondition = ref(false);

const supabase = useSupabase();
const { addToastError } = useToast();

const { isTeamMemberManager, workspaceId, teamMember } = useUserMe();

const isIndependent = teamMember.value?.main_workspace_id !== ASAP_WORKSPACE_ID;

const commercialConditionOwner = props.commercialCondition?.auth_user.team_member;

const disabledDocumentPreview =
  // // is independent & not owner of the commercial condition
  (isIndependent && commercialConditionOwner?.workspace_id !== workspaceId.value) ||
  // // is independent & not a validated commercial condition
  (isIndependent && props.commercialCondition && props.commercialCondition.status === "to_validate");

onMounted(() => {
  if (commercialConditionOwner?.workspace_id === ASAP_WORKSPACE_ID) isAsapCommercialCondition.value = true;
});

const updateStatus = async function (status: CommercialConditionStatus) {
  try {
    if (!props.commercialCondition) return;
    if (
      // Check roles (teamMemberManager or referent) and status
      (!isTeamMemberManager.value &&
        commercialConditionOwner?.manager_id !== teamMember.value?.id &&
        props.commercialCondition.status === "to_validate" &&
        status !== "canceled") ||
      (props.commercialCondition.status === "canceled" && status !== "to_validate")
    ) {
      console.log("in if");
      throw new Error("Vous n'avez pas les droits pour changer ce statut");
    }

    emit("update:commercialCondition", { ...props.commercialCondition, status });

    await supabase.from("commercial_condition").update({ status }).eq("id", props.commercialCondition.id);
  } catch (error) {
    addToastError({ title: "Une erreur est survenue lors du changement de statut" }, error);
  }
};

const isUploading = ref(false);

const commercialConditionSignedFile = ref<SupabaseFile>();

const { addToast } = useToast();

const uploadSignedFile = async function (signedFileId: string) {
  if (!props.commercialCondition) return;

  isUploading.value = true;

  const { data, error } = await supabase
    .from("commercial_condition")
    .update({ signed_file_id: signedFileId })
    .eq("id", props.commercialCondition.id)
    .select(commercialConditionColumns)
    .single();

  if (error) throw error;

  emit("update:commercialCondition", data);

  await updateStatus("signed");

  addToast({
    type: "success",
    title: "Bravo la ziz! 😎👍👍",
    description: "Le document signé a été téléchargé avec succès !",
  });

  showUploadSignedDocumentDialog.value = false;

  isUploading.value = false;
};

const fileToDisplay = computed(
  () => props.commercialCondition?.signed_file ?? props.commercialCondition?.generated_file
);
</script>

<style scoped lang="scss">
.ActionButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-inline: 12px;
  height: 32px;
  border-radius: 100px;
  background: var(--gray-6);
  transition: var(--transition-1);
  cursor: pointer;
}

.PreviewFile {
  height: 36px;
  width: 24px;
  flex-shrink: 0;
  border-radius: 3px;
  object-fit: cover;
  object-position: left top;
  border: 1px solid var(--gray-6);
}

.SignedFile {
  height: 256px;
  width: 196px;
  border-radius: 5px;
  object-fit: cover;
  object-position: left top;
}
</style>
